<template>
  <div>
    <!-- Add New Sidebar -->
    <app-list-sidebar
      :is-app-sidebar-active.sync="isAppSidebarActive"
      :data="appInfo"
      @updated="fetchAppsByMerchant"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="apps"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="app_id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <!-- Column: User -->
        <template #cell(app)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.icon_url"
                :text="data.item.app_name"
                variant="primary"
              />
            </template>
            <b-link
              :to="{ name: 'merchant-app-channels',
                     query: { merchant_no: data.item.merchant_no, app_id: data.item.app_id },
                     params: { merchant_name: merchantName, app_name: data.item.app_name, region: data.item.region }
              }"
              class="font-weight-bold d-block"
            >
              {{ data.item.app_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.app_id }}</small>
          </b-media>
        </template>

        <!-- Column: Region -->
        <template #cell(region)="data">
          {{ data.item.region_name }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="showDetail(data.item)"
            />

            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="$router.push({ name: 'merchant-app-configs', query: { merchant_no: data.item.merchant_no, app_id: data.item.app_id }, params: { merchant_name: merchantName, app_name: data.item.app_name } })"
            />

            <feather-icon
              icon="WindIcon"
              size="16"
              class="cursor-pointer"
              @click="$router.push({ name: 'merchant-app-risk-configs', query: { merchant_no: data.item.merchant_no, app_id: data.item.app_id }, params: { merchant_name: merchantName, app_name: data.item.app_name } })"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalApps"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BFormInput, BButton, BMedia, BAvatar, BLink,
  BPagination, BBadge,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'
import AppListSidebar from './AppListSidebar.vue'

const {
  fetchMerchantAppsDetail,
} = useMerchantsJs()

export default {
  components: {
    AppListSidebar,

    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BBadge,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      apps: [],
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      tableFields: [
        'app',
        'region',
        'industry',
        { key: 'create_time', label: 'REGISTER DATE' },
        'status',
        'actions',
      ],
      usersTableFields: [
        'nick_name',
        'real_name',
        'actions',
      ],
      currentPage: 1,
      totalApps: 0,
      filter: null,
      filterOn: ['app_id', 'app_name', 'index_url', 'contact_email'],

      isAppSidebarActive: false,
      appInfo: {},
    }
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
  },
  created() {
    this.fetchAppsByMerchant()
  },
  methods: {
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalApps - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalApps
      this.dataMeta.of = this.totalApps
    },
    fetchAppsByMerchant() {
      fetchMerchantAppsDetail(
        this.merchantNo,
        apps => {
          this.apps = apps
          this.totalApps = apps.length
          this.recalMetaData()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === '0') return 'Disabled'
      if (status === '1') return 'Enabled'
      return 'Unknown'
    },
    onFiltered(filteredItems) {
      this.totalApps = filteredItems.length
      this.currentPage = 1
    },
    showDetail(appDetail) {
      this.appInfo = appDetail
      this.isAppSidebarActive = true
    },
  },
}
</script>
