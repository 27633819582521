<template>
  <b-sidebar
    id="app-detail-sidebar"
    :visible="isAppSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-app-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          APP Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <b-button
            v-if="!appInfo.iconUrl"
            variant="primary"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          >
            Upload LOGO
          </b-button>

          <b-img
            v-if="appInfo.iconUrl"
            thumbnail
            fluid
            center
            :src="appInfo.iconUrl"
            alt="Image"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          />
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @change="inputImageRenderer"
          >

          <!-- APP ID -->
          <b-form-group
            label="ID"
            label-for="app-id"
          >
            <b-form-input
              id="app-id"
              v-model="appInfo.appId"
              autofocus
              trim
              disabled
            />
          </b-form-group>

          <!-- APP Name -->
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="app-name"
            >
              <b-form-input
                id="app-name"
                v-model="appInfo.appName"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Index -->
          <validation-provider
            #default="{ errors }"
            name="Index"
            rules="required"
          >
            <b-form-group
              label="Index"
              label-for="app-index"
            >
              <b-form-input
                id="app-index"
                v-model="appInfo.indexUrl"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact Name -->
          <validation-provider
            #default="{ errors }"
            name="Contact Name"
            rules="required"
          >
            <b-form-group
              label="Contact Name"
              label-for="app-contact-name"
            >
              <b-form-input
                id="app-contact-name"
                v-model="appInfo.contact"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact Email -->
          <validation-provider
            #default="{ errors }"
            name="Contact Email"
            rules="required|email"
          >
            <b-form-group
              label="Contact Email"
              label-for="app-contact-email"
            >
              <b-form-input
                id="app-contact-email"
                v-model="appInfo.contactEmail"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact Phone -->
          <validation-provider
            #default="{ errors }"
            name="Contact Phone"
            rules="required"
          >
            <b-form-group
              label="Contact Phone"
              label-for="app-contact-phone"
            >
              <b-form-input
                id="app-contact-phone"
                v-model="appInfo.phone"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Region -->
          <b-form-group
            label="Region"
            label-for="merchant-status"
          >
            <v-select
              v-model="appInfo.region"
              :options="regionOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="app-region"
              @option:selected="regionChange"
            />
          </b-form-group>

          <!-- Industry -->
          <b-form-group
            label="Industry"
            label-for="app-industry"
          >
            <v-select
              v-model="appInfo.industry"
              :options="industryOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="app-industry"
            />
          </b-form-group>

          <!-- Review Level -->
          <b-form-group
            label="Review Level"
            label-for="review-level"
          >
            <v-select
              v-model="appInfo.reviewLevel"
              :options="reviewLevelOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="review-level"
            />
          </b-form-group>

          <!-- Report Email -->
          <b-form-group
            label="Report Email"
            label-for="app-report-email"
          >
            <b-form-input
              id="app-report-email"
              v-model="appInfo.reportEmail"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Notify Email -->
          <b-form-group
            label="Notify Email"
            label-for="app-notify-email"
          >
            <b-form-input
              id="app-notify-email"
              v-model="appInfo.notifyEmail"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Custom Pay URL -->
          <b-form-group
            label="Custom Pay URL"
            label-for="app-custom-pay-url"
          >
            <b-form-input
              id="app-custom-pay-url"
              v-model="appInfo.customPayUrl"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for="app-status"
          >
            <v-select
              v-model="appInfo.status"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="app-status"
            />
          </b-form-group>

          <el-divider class="mt-3 mb-3" />

          <!-- API RESTRICTION -->
          <b-form-group
            label="API RESTRICTION"
            label-for="api-restriction"
          >
            <v-select
              v-model="endpoint"
              input-id="api-restriction"
              :options="apiOptions"
              :clearable="false"
              :searchable="false"
              :reduce="val => val.value"
              multiple
            />
          </b-form-group>

          <!-- Order Currency -->
          <b-form-group
            label="Order Currency"
            label-for="app-order-currency"
          >
            <v-select
              v-model="orderCurrency"
              :options="currencyOptions"
              :clearable="false"
              :searchable="false"
              multiple
              input-id="app-order-currency"
            />
          </b-form-group>

          <!-- Trading Currency -->
          <b-form-group
            label="Trading Currency"
            label-for="app-trading-currency"
          >
            <v-select
              v-model="tradingCurrency"
              :options="currencyOptions"
              :clearable="false"
              :searchable="false"
              input-id="app-trading-currency"
            />
          </b-form-group>

          <!-- APP Website Img -->
          <b-form-group
            label="APP Website Img"
            label-for="app-website-img"
          >
            <b-form-file
              ref="file-input"
              v-model="appInfo.appImgList"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
              multiple
              input-id="app-website-img"
              @change="changeFile"
            />
          </b-form-group>
          <div>
            <ul class="app-list">
              <li v-for="(item, index) in appImgListArray" :key="index">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">
                  <img :src="fileType(item.type)" alt="" class="app-list-image">
                  <span class="app-list-text">{{ item.name }}</span>

                </a>
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                  class="mr-50 delete-icon"
                  @click="deleteFile(index)"
                />
              </li>
            </ul>
          </div>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isFormValid"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BImg, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { showToast, showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  fetchAppConfigs,
  updateApp,
  fetchIndustryOptions,
  uploadKycFile,
  fetchCommonOptions,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppSidebarActive',
    event: 'update:is-app-sidebar-active',
  },
  props: {
    isAppSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      appInfo: {
        merchantNo: '',
        appId: '',
        appName: '',
        contact: '',
        contactEmail: '',
        iconUrl: '',
        indexUrl: '',
        mockIndexUrl: '',
        phone: '',
        region: '',
        reportEmail: '',
        notifyEmail: '',
        customPayUrl: '',
        industry: '',
        reviewLevel: '',
        status: '',
        appImgList: [],
      },
      appImgListArray: [],
      orderCurrency: [],
      tradingCurrency: '',
      error: '',
      industryOptions: [],

      regionOptions: [],

      statusOptions: [
        { label: 'Disable', value: '0' },
        { label: 'Enable', value: '1' },
      ],

      reviewLevelOptions: [
        { label: 'All Pass', value: '1' },
        { label: 'Review', value: '2' },
        { label: 'Regular', value: '3' },
      ],

      currencyOptions: [],

      endpoint: '',
      apiOptions: [
        { label: 'Checkout Redirect', value: 'WEB' },
        { label: 'API Direct', value: 'API' },
        { label: 'Pre-authorization', value: 'PA' },
      ],

      required,
      alphaNum,
      email,
      // eslint-disable-next-line global-require
      docPng: require('@/assets/images/filetype/doc.png'),
      // eslint-disable-next-line global-require
      docxPng: require('@/assets/images/filetype/docx.png'),
      // eslint-disable-next-line global-require
      emptyFilePng: require('@/assets/images/filetype/empty-file.png'),
      // eslint-disable-next-line global-require
      jpegPng: require('@/assets/images/filetype/jpeg.png'),
      // eslint-disable-next-line global-require
      jpgPng: require('@/assets/images/filetype/jpg.png'),
      // eslint-disable-next-line global-require
      pdfPng: require('@/assets/images/filetype/pdf.png'),
      // eslint-disable-next-line global-require
      pngPng: require('@/assets/images/filetype/png.png'),
      // eslint-disable-next-line global-require
      xlsPng: require('@/assets/images/filetype/xls.png'),
      // eslint-disable-next-line global-require
      xlsxPng: require('@/assets/images/filetype/xlsx.png'),
    }
  },
  computed: {
    isFormValid() {
      let ret = false
      ret = this.endpoint.length > 0
      return ret
    },
  },
  watch: {
    isAppSidebarActive(val) {
      if (!val) {
        return
      }
      this.initAppInfo(this.data)
    },
  },
  created() {
    fetchIndustryOptions(
      options => {
        this.industryOptions = options
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `Fetch industry options fail with ${fail}`)
      },
    )
    fetchCommonOptions(
      options => {
        const { countries, currencies } = options
        countries.forEach(item => {
          const region = {
            label: item.region_name,
            value: item.region_code,
          }
          this.regionOptions.push(region)
        })

        currencies.forEach(item => {
          this.currencyOptions.push(item.code)
        })
        this.currencyOptions.push('%')
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `Fetch options fail with ${fail}`)
      },
    )
  },
  methods: {
    initAppInfo(info) {
      fetchAppConfigs(
        info.app_id,
        configs => {
          configs.forEach(item => {
            if (item.key === 'order_currency') {
              this.orderCurrency = item.value.split(',')
            }
            if (item.key === 'trade_currency') {
              this.tradingCurrency = item.value
            }
            if (item.key === 'api_restrictions') {
              this.endpoint = item.value.split(',')
            }
          })
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
      this.appInfo = {
        merchantNo: info.merchant_no,
        appId: info.app_id,
        appName: info.app_name,
        contact: info.contact,
        contactEmail: info.contact_email,
        iconUrl: info.icon_url,
        indexUrl: info.index_url,
        mockIndexUrl: info.mock_index_url,
        phone: info.phone,
        region: info.region,
        industry: this.formatIndustry(info.industry),
        reviewLevel: `${info.review_level}`,
        reportEmail: info.report_email,
        notifyEmail: info.notify_email,
        customPayUrl: info.custom_pay_url,
        status: info.status,
      }
      if (info.app_img_list.length > 0) {
        this.appImgListArray = this.getFileObject(info.app_img_list)
      }
    },
    initValues() {
      this.appInfo = {
        merchantNo: '',
        appId: '',
        appName: '',
        contact: '',
        contactEmail: '',
        iconUrl: '',
        indexUrl: '',
        mockIndexUrl: '',
        phone: '',
        region: '',
        industry: '',
        reviewLevel: '',
        reportEmail: '',
        notifyEmail: '',
        customPayUrl: '',
        status: '',
        appImgList: [],
      }
      this.endpoint = []
      this.orderCurrency = []
      this.tradingCurrency = ''
      this.appImgListArray = []
    },
    regionChange() {
    },
    formatIndustry(val) {
      let ret = 0
      this.industryOptions.forEach(item => {
        if (item.label === val) {
          ret = item.value
        }
      })
      return ret
    },
    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size / 1024 > 64) {
          this.$bvToast.toast('File is too large, Please control the file size within 64k.', {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        const reader = new FileReader()
        reader.onload = e => {
          this.appInfo.iconUrl = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.update()
            }
          })
        }
      })
    },
    update() {
      // 编辑config配置list
      const configs = []
      const curOrder = { key: 'order_currency', value: this.orderCurrency.join(',') }
      const curTrading = { key: 'trade_currency', value: this.tradingCurrency }
      const endpoint = { key: 'api_restrictions', value: this.endpoint.join(',') }
      configs.push(curOrder)
      configs.push(curTrading)
      configs.push(endpoint)

      const obj = {
        merchant_no: this.appInfo.merchantNo,
        app_id: this.appInfo.appId,
        app_name: this.appInfo.appName,
        contact: this.appInfo.contact,
        contact_email: this.appInfo.contactEmail,
        icon_url: this.appInfo.iconUrl,
        index_url: this.appInfo.indexUrl,
        phone: this.appInfo.phone,
        region: this.appInfo.region,
        industry: this.appInfo.industry,
        review_level: this.appInfo.reviewLevel,
        report_email: this.appInfo.reportEmail,
        notify_email: this.appInfo.notifyEmail,
        custom_pay_url: this.appInfo.customPayUrl,
        status: this.appInfo.status,
        app_img_list: this.appImgListArray.map(value => value.url),
        configs,
      }

      if (this.app_id !== null && this.app_id !== '') {
        updateApp(
          obj,
          () => {
            showAlert(this, 'success', 'Updated!', 'Success')
            this.$emit('update:is-app-sidebar-active', false)
            this.$emit('updated')
            this.initValues()
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      }
    },
    changeFile(event) {
      const fileList = event.target.files
      this.uploadFile(fileList)
    },
    uploadFile(fileList) {
      if (!fileList || fileList.length <= 0) {
        showToast(this, 'Warning', 'No File Select')
        return
      }
      const params = new FormData()
      fileList.forEach(file => {
        params.append('files', file)
      })
      const that = this
      that.$showLoading()
      uploadKycFile(
        res => {
          that.$hideLoading()
          const { urlList } = res
          that.appImgListArray = that.getFileObject(urlList).concat(that.appImgListArray)
          that.unique(that.kycFilesArray)
          that.$refs['file-input'].reset()
          showToast(that, 'Success', 'Success')
        },
        fail => {
          that.$hideLoading()
          showToast(this, 'Warning', `Upload proofs fail with ${fail}`)
        },
        params,
      )
    },
    getFileObject(list) {
      if (list.length <= 0) return []
      const fileArray = []
      list.forEach(value => {
        const file = {}
        const nameIndex = value.lastIndexOf('/') + 1
        const typeIndex = value.lastIndexOf('.') + 1
        file.name = value.substring(nameIndex)
        file.url = value
        file.type = value.substring(typeIndex)
        fileArray.push(file)
      })
      // eslint-disable-next-line consistent-return
      return fileArray
    },
    unique(arr) {
      if (!Array.isArray(arr)) {
        return
      }
      const urls = []
      const array = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        if (urls.indexOf(arr[i].url) === -1) {
          urls.push(arr[i].url)
          array.push(arr[i])
        }
      }
      // eslint-disable-next-line consistent-return
      return array
    },
    deleteFile(index) {
      this.appImgListArray.splice(index, 1)
    },
    fileType(type) {
      if (type === 'doc') {
        return this.docPng
      } if (type === 'docx') {
        return this.docxPng
      } if (type === 'jpeg' || type === 'jpe') {
        return this.jpegPng
      } if (type === 'jpg') {
        return this.jpgPng
      } if (type === 'pdf') {
        return this.pdfPng
      } if (type === 'png') {
        return this.pngPng
      } if (type === 'xls') {
        return this.xlsPng
      } if (type === 'xlsx') {
        return this.xlsxPng
      }
      return this.emptyFilePng
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.app-list {
  padding: 0;
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    border-bottom: 1px solid #DCDFE6;
    padding-bottom: 6px;
    margin-bottom: 10px;
    a {
      color: #333;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .delete-icon {
      cursor: pointer;
    }
    .app-list-image {
      width: 22px;
      height: auto;
      align-self: flex-start;
    }
    .app-list-text {
      margin-left: 10px;
    }
  }

}
</style>
